	/*
  	Flaticon icon font: Flaticon
  	Creation date: 25/02/2020 17:58
  	*/

@font-face {
    font-family: "Flaticon";
    src: url("../fonts/Flaticon.eot");
    src: url("../fonts/Flaticon.eot?#iefix") format("embedded-opentype"),
        url("../fonts/Flaticon.woff2") format("woff2"),
        url("../fonts/Flaticon.woff") format("woff"),
        url("../fonts/Flaticon.ttf") format("truetype"),
        url("../fonts/Flaticon.svg#Flaticon") format("svg");
    font-weight: normal;
    font-style: normal;
}
@media screen and (-webkit-min-device-pixel-ratio:0) {
    @font-face {
        font-family: "Flaticon";
        src: url("../fonts/Flaticon.svg#Flaticon") format("svg");
    }
}
[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
    font-family: Flaticon;
    font-style: normal;
}

.flaticon-award:before { content: "\f100"; }
.flaticon-credit-card:before { content: "\f101"; }
.flaticon-clock:before { content: "\f102"; }
.flaticon-cleaning-spray:before { content: "\f103"; }
.flaticon-clean-house:before { content: "\f104"; }
.flaticon-wiper:before { content: "\f105"; }